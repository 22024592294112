export function serializeMap(map) {
    return Array.from(map.entries());
}
export function deserializeMap(kvpairs) {
    return new Map(kvpairs);
}
export function serializeSet(set) {
    return Array.from(set);
}
export function deserializeSet(set) {
    return new Set(set);
}
export function replacer(key, value) {
    if (value instanceof Map) {
        return serializeMap(value);
    }
    else if (value instanceof Set) {
        return serializeSet(value);
    }
    else {
        return value;
    }
}
