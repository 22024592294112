export const settingsModule = {
    namespaced: true,
    state: {
        volume: 100,
        locale: 'en',
    },
    mutations: {
        UPDATE(state, settings) {
            Object.assign(state, settings);
            localStorage.setItem('settings', JSON.stringify(state));
        },
    },
    actions: {
        load(context) {
            var _a;
            let loaded = JSON.parse((_a = localStorage.getItem('settings')) !== null && _a !== void 0 ? _a : '{}');
            context.commit('UPDATE', loaded);
        },
    },
};
