import { __decorate } from "tslib";
import Vue from 'vue';
import { ToastStyle } from '@/models/toast';
import { RoomRequestType } from 'common/models/messages';
import Component from 'vue-class-component';
import { API } from '@/common-http';
let ToastNotification = class ToastNotification extends Vue {
    constructor() {
        super(...arguments);
        this.padding = 8;
        this.closeTimeoutId = null;
        this.ToastStyle = ToastStyle;
    }
    get color() {
        if (this.toast.style === ToastStyle.Success) {
            return "green";
        }
        else if (this.toast.style === ToastStyle.Error) {
            return "red";
        }
        return undefined;
    }
    get undoable() {
        if (!this.toast.event) {
            return false;
        }
        let eventType = this.toast.event.request.type;
        return eventType === RoomRequestType.SeekRequest || eventType === RoomRequestType.SkipRequest || eventType === RoomRequestType.AddRequest || eventType === RoomRequestType.RemoveRequest;
    }
    created() {
        if (this.toast.duration) {
            this.closeTimeoutId = setTimeout(() => {
                this.close();
            }, this.toast.duration);
        }
    }
    destroyed() {
        if (this.closeTimeoutId) {
            clearTimeout(this.closeTimeoutId);
        }
    }
    close() {
        this.$toast.remove(this.toast.id);
    }
    async undo() {
        try {
            await API.post(`/room/${this.$route.params.roomId}/undo`, { data: { event: this.toast.event } });
            this.close();
        }
        catch (err) {
            this.$toast.add({
                style: ToastStyle.Error,
                content: err.message,
                duration: 4000,
            });
        }
    }
};
ToastNotification = __decorate([
    Component({
        name: "ToastNotification",
        props: {
            toast: {
                type: Object,
            },
            number: {
                type: Number,
            },
        },
    })
], ToastNotification);
export default ToastNotification;
