export var RoomRequestType;
(function (RoomRequestType) {
    RoomRequestType[RoomRequestType["JoinRequest"] = 0] = "JoinRequest";
    RoomRequestType[RoomRequestType["LeaveRequest"] = 1] = "LeaveRequest";
    RoomRequestType[RoomRequestType["PlaybackRequest"] = 2] = "PlaybackRequest";
    RoomRequestType[RoomRequestType["SkipRequest"] = 3] = "SkipRequest";
    RoomRequestType[RoomRequestType["SeekRequest"] = 4] = "SeekRequest";
    RoomRequestType[RoomRequestType["AddRequest"] = 5] = "AddRequest";
    RoomRequestType[RoomRequestType["RemoveRequest"] = 6] = "RemoveRequest";
    RoomRequestType[RoomRequestType["OrderRequest"] = 7] = "OrderRequest";
    RoomRequestType[RoomRequestType["VoteRequest"] = 8] = "VoteRequest";
    RoomRequestType[RoomRequestType["PromoteRequest"] = 9] = "PromoteRequest";
    RoomRequestType[RoomRequestType["UpdateUser"] = 10] = "UpdateUser";
    RoomRequestType[RoomRequestType["ChatRequest"] = 11] = "ChatRequest";
    RoomRequestType[RoomRequestType["UndoRequest"] = 12] = "UndoRequest";
    RoomRequestType[RoomRequestType["ApplySettingsRequest"] = 13] = "ApplySettingsRequest";
    RoomRequestType[RoomRequestType["PlayNowRequest"] = 14] = "PlayNowRequest";
    RoomRequestType[RoomRequestType["ShuffleRequest"] = 15] = "ShuffleRequest";
})(RoomRequestType || (RoomRequestType = {}));
