import { RoomRequestType } from 'common/models/messages';
import _ from 'lodash';
export const toastModule = {
    namespaced: true,
    state: {
        notifications: [],
    },
    mutations: {
        ADD_TOAST(state, notification) {
            var _a, _b, _c, _d, _e, _f, _g;
            if (state.notifications.length > 0) {
                const last = state.notifications[state.notifications.length - 1];
                if (((_a = notification.event) === null || _a === void 0 ? void 0 : _a.request.type) === ((_b = last.event) === null || _b === void 0 ? void 0 : _b.request.type)) {
                    if (((_c = last.event) === null || _c === void 0 ? void 0 : _c.request.type) === RoomRequestType.PlaybackRequest) {
                        state.notifications.splice(state.notifications.length - 1, 1);
                    }
                    else if (((_d = last.event) === null || _d === void 0 ? void 0 : _d.request.type) === RoomRequestType.SeekRequest && ((_e = last.event) === null || _e === void 0 ? void 0 : _e.user.name) === ((_f = notification.event) === null || _f === void 0 ? void 0 : _f.user.name)) {
                        let removed = state.notifications.splice(state.notifications.length - 1, 1);
                        notification.event.additional.prevPosition = (_g = removed[0].event) === null || _g === void 0 ? void 0 : _g.additional.prevPosition;
                    }
                }
            }
            state.notifications.push({
                ...notification,
                id: Symbol(),
            });
        },
        REMOVE_TOAST(state, id) {
            const idx = _.findIndex(state.notifications, { id });
            state.notifications.splice(idx, 1);
        },
        CLEAR_ALL_TOASTS(state) {
            state.notifications = [];
        },
    },
};
