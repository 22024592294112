import { __decorate } from "tslib";
import Vue from 'vue';
import Component from 'vue-class-component';
import ToastNotification from "@/components/ToastNotification.vue";
/**
 * Handles displaying all toast notifications.
 */
let Notifier = class Notifier extends Vue {
    closeAll() {
        this.$store.commit("toast/CLEAR_ALL_TOASTS");
    }
};
Notifier = __decorate([
    Component({
        name: "Notifier",
        components: {
            ToastNotification,
        },
    })
], Notifier);
export default Notifier;
