const plugin = {
    install(vue, options) {
        const store = options.store;
        vue.prototype.$toast = {
            add(toast) {
                store.commit("toast/ADD_TOAST", toast);
            },
            remove(id) {
                store.commit("toast/REMOVE_TOAST", id);
            },
        };
    },
};
export default plugin;
