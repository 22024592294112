import { Role } from 'common/models/types';
import { API } from "@/common-http";
export const usersModule = {
    state: {
        you: {
            id: "",
            name: "",
            isLoggedIn: false,
            role: Role.UnregisteredUser,
            isYou: true,
            grants: 4194303,
        },
    },
    getters: {
        token() {
            return window.localStorage.getItem("token");
        },
    },
    mutations: {
        SET_YOU(state, payload) {
            state.you = Object.assign(state.you, payload);
        },
        SET_AUTH_TOKEN(state, token) {
            window.localStorage.setItem("token", token);
        },
    },
    actions: {
        user(context, message) {
            if (message.user.isYou) {
                context.commit("SET_YOU", message.user);
            }
        },
        async getNewToken(context) {
            const resp = await API.get("/auth/grant");
            context.commit("SET_AUTH_TOKEN", resp.data.token);
        },
        async waitForToken(context) {
            if (context.getters.token) {
                return;
            }
            return new Promise((resolve) => {
                this.subscribe((mutation) => {
                    if (mutation.type === "SET_AUTH_TOKEN") {
                        resolve();
                    }
                });
            });
        },
    },
};
